
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { Button } from "reactstrap";
import BaseLayout from "@components/BaseLayout";
import TranslatedHtml from "@components/common/TranslatedHtml";
import { Routes } from "@services/routes";
/**
 * this page is the front page of the app
 */
const FrontPage: React.FC = () => {
    const { t } = useTranslation("common");
    return <BaseLayout pageTitle={t("page.index.title")} isFrontPage={true}>
    <div className="frontpage-content">
      <h1 role="heading" aria-level={2} className="frontpage-title">{t("page.index.heading")}</h1>
      <span className="frontpage-subtitle">
        <TranslatedHtml content="page.index.quote"/>
      </span>
      <Link href={Routes.Marketplace} passHref>
        <Button className="btn-frontpage">
          <span className="title">{t("page.index.buttonTitle")}</span>
          {t("page.index.button")}
        </Button>
      </Link>
    </div>
  </BaseLayout>;
};
export default FrontPage;

    async function __Next_Translate__getStaticProps__191db775bac__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191db775bac__ as getStaticProps }
  